import React from "react";
import "./index.css";

const s3_base = "https://cdn.huntakiller.com/huntakiller/s13/";

export default function Home() {
  return (
    <div className="homeContainer">
      <div>
        <div className="homeHeader"></div>
        <div className="text1">
          The 13th Annual White Hilt Renaissance Faire <br></br>
          <br></br>The grandest Renaissance festival in the Midwest
        </div>
        <img
          className="center"
          style={{ width: "60%" }}
          src="/13/whr/whr-divider.svg"
          alt="divider"
        />
        <video controls width="100%" height="70%">
          <source src={s3_base + "whr-renfaire-commercial-video.mp4"} />
          This browser does not support the HTML5 video element.
        </video>
        <img src={s3_base + "whr-renfaire-home-image.jpg"} alt="Renfaire Home" />

        <div className="text2">
          We are unbelievably excited to welcome you all back after a year away!{" "}
        </div>
        <div className="text3">
          For the auspicious 13th season, the White Hilt Renaissance Faire is
          proud to present history’s greatest tale of brotherhood, betrayal, and
          bloodshed: The Death of King Arthur! <br></br>
          <br></br>The story begins as the sun was setting on the adventures of
          the famed Knights of the Round Table. Fresh from their quest for the
          Holy Grail, their bond was never stronger. But while no man or beast
          could destroy these brave knights, the love of a maiden would be their
          undoing. For King Arthur’s most trusted companion, Sir Lancelot, fell
          in love with his Queen Guinevere… <br></br>
          <br></br>Witness a forbidden love, the betrayal of brothers, and the
          machinations of a mysterious evil this summer at the White Hilt Faire.
        </div>
      </div>
    </div>
  );
}
