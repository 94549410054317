import React, { useState, useContext } from "react";
import { Header } from "./ModalHeader";
import "./viewer.css";
import Draggable from "react-draggable";
import { GameStateContext } from "../../context/context";
import { useWindowSize } from "../../_shared/utils";

const PDFViewer = ({ vumail, icon, close, openContact, parent }) => {
  const s3_base = "https://cdn.huntakiller.com/huntakiller/s13/";
  const [loading, setLoading] = useState(true);
  const imageLoaded = () => setLoading(false);
  const context = useContext(GameStateContext);
  let { episode } = context?.getState();
  const nodeRef = React.useRef(null);
  const imgRef = React.useRef(null);

  const [current, setCurrent] = useState(0);
  const backClick = () => {
    current > 0 && setCurrent(current - 1);
    let imgContainer = imgRef?.current;
    imgContainer.scrollTop = 0;
  };
  const fwClick = () => {
    current < icon.url.length - 1 && setCurrent(current + 1);
    let imgContainer = imgRef?.current;
    imgContainer.scrollTop = 0;
  };
  const [imgWidth, setImgWidth] = useState(100);
  const [fetching, setFetching] = useState(false);

  const zoom = (zoomIn) => {
    let widthPercent = imgWidth;
    widthPercent = zoomIn
      ? Math.max(widthPercent - 20, 25)
      : Math.min(widthPercent + 20, 350);
    setImgWidth(widthPercent);
  };

  const size = useWindowSize();

  const marginTop = icon?.top ? "0%" : "5%";
  const marginLeft = icon?.top ? "2%" : "0%";
  const marginBottom = icon?.top ? "7%" : "2%";

  const download = (url, name, e) => {
    e.preventDefault();
    if (!fetching) {
      setFetching(true);
      fetch(url + "?crossorigin")
        .then((response) => response.blob())
        .then((blob) => {
          setFetching(false);
          const blobURL = URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = blobURL;
          a.style = "display: none";

          if (name && name.length)
            a.download =
              name + (icon.url.length > 1 ? "-" + (parseInt(current) + 1) : "");
          document.body.appendChild(a);
          a.click();
        })
        .catch(() => this.setState({ error: true }));
    }
  };

  return (
    <>
      <Draggable
        nodeRef={nodeRef}
        handle={
          icon.child
            ? ".hak-modal-header-label-secondlevel"
            : ".hak-modal-header-label"
        }
        bounds={icon.child ? "#folder-bounds" : "#draggable-bounds"}
      >
        <div
          ref={nodeRef}
          className="hak-image-box"
          style={{ top: marginTop, left: marginLeft, bottom: marginBottom }}
        >
          <div className="hak-image-row header">
            <Header
              vumail={vumail}
              icon={icon}
              close={close}
              parent={parent}
              secondLevel={Boolean(icon.child)}
            />
          </div>

          <div
            ref={imgRef}
            className="hak-image-row content"
            style={{ height: icon?.vh ? icon.vh + "vh" : "84vh" }}
          >
            <img
              style={{
                width: imgWidth + "%",
                display: loading ? "none" : "block",
              }}
              onLoad={imageLoaded}
              src={s3_base + icon.url[current]}
              alt={"File not available!"}
            />
          </div>

          <div className="hak-image-row footer">
            <div className="d-flex justify-content-between p-1">
              {icon.url.length > 1 && (
                <div style={{ borderBottom: "0px" }}>
                  <button className={"btn btn-sm btn-dark"} onClick={backClick}>
                    {" < "}
                  </button>
                  <span className={"p-2 mt-3"}>
                    {"page: "}
                    {current + 1}
                    {"/"}
                    {icon.url.length}
                  </span>
                  <button className={"btn btn-sm btn-dark"} onClick={fwClick}>
                    {" > "}
                  </button>
                </div>
              )}

              <div style={{ borderBottom: "0px" }}>
                <button
                  disabled={imgWidth >= 350}
                  className={"btn btn-sm"}
                  onClick={() => zoom(false)}
                >
                  <img
                    style={{ width: "20px" }}
                    src="https://cdn.huntakiller.com/huntakiller/s10/zoomin-icon.svg"
                    alt="zoom in"
                  />
                </button>
                <button
                  disabled={imgWidth <= 25}
                  className={"btn btn-sm"}
                  onClick={() => zoom(true)}
                >
                  <img
                    style={{ width: "20px" }}
                    src="https://cdn.huntakiller.com/huntakiller/s10/zoomout-icon.svg"
                    alt="zoom out"
                  />
                </button>
                <button
                  className={"btn btn-sm"}
                  onClick={(e) =>
                    download(s3_base + icon.url[current], icon.label, e)
                  }
                >
                  <img
                    style={{ width: "17px" }}
                    src="https://cdn.huntakiller.com/huntakiller/s10/download-icon.svg"
                    alt="download"
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
      </Draggable>
    </>
  );
};

export default PDFViewer;
