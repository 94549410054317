import { GameStateContext } from './context';

export function GameStateProvider(props) {

    const local_key = "hak_13_state"

    const getState = () => {
        let val = localStorage.getItem(local_key);
        if(val && JSON.parse(val)){
            return JSON.parse(val)
        }
        else{
            return {}
        }
    }

    const updateState = (newState) => {
        localStorage.setItem(local_key, JSON.stringify(newState));   
    }

    return (
        <GameStateContext.Provider
            value={{
                getState: () => getState(),
                resetState: () => updateState({}),
                updateState: (newState) => updateState({...getState(), ...newState})
            }}
        >
            {props.children}
        </GameStateContext.Provider>
    );
}