import React from 'react';
import ReactDOM from 'react-dom'
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom'
import './index.css';
import App from './App';

const routing = (
	<Router>
		<Switch>
			<Route exact path="/" component={App} />
			{/* <Route exact path="/securityportal" component={SecurityPortal} /> */}
		</Switch>
	</Router>
)

ReactDOM.render(routing, document.getElementById('root'))