export const oidcConfig = {
  staging: {
    onSignIn: async () => {
      window.location = window.location.origin;
    },
    authority: 'https://id-qa.huntakiller.com',
    clientId: 'qa-hak-13',
    responseType: 'code',
    redirectUri: window.location.origin,
    postLogoutRedirectUri: window.location.origin,
    automaticSilentRenew: true,
  },
  development: {
    onSignIn: async () => {
      window.location = window.location.origin;
    },
    authority: 'https://id-dev.huntakiller.com',
    clientId: 'dev-digital-story-1',
    responseType: 'code',
    redirectUri: window.location.origin,
    postLogoutRedirectUri: window.location.origin,
    automaticSilentRenew: true,
  },
  production: {
    onSignIn: async () => {
      window.location = window.location.origin;
    },
    authority: 'https://id.huntakiller.com',
    clientId: 'prod-hak-13',
    responseType: 'code',
    redirectUri: window.location.origin,
    postLogoutRedirectUri: window.location.origin,
    automaticSilentRenew: true,
  }
}