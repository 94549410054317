import React from "react";

const s3_base = "https://cdn.huntakiller.com/huntakiller/s13/";

export default function Hours() {
  return (
    <div className="homeContainer">
      <div>
        <img src={s3_base + "whr-hours-pricing-image.jpg"} alt="Hours & Pricing" />
        <div className="text1">
          OPEN JUNE THROUGH AUGUST <br></br>1 PM – 8 PM Monday – Thursday{" "}
          <br></br>
          11 AM – 9 PM Friday – Sunday <br></br>
          <br></br>
          <br></br>
          CLOSED FOR 4TH OF JULY
          <br></br>
          <br></br>
          TICKETS: <br></br>
          $25 Adults <br></br>
          $10 Kids under 12 <br></br>
          Children under 3 granted free entry
        </div>
      </div>
    </div>
  );
}
