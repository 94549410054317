import React from "react";

const s3_base = "https://cdn.huntakiller.com/huntakiller/s13/";

export default function South() {
  return (
    <div className="homeContainer">
      <div>
        <img src={s3_base + "whr-southohioregionalshakespeareco-image.jpg"} alt="South Ohio Regional Shakespeare Co" />
        <div style={{ padding: "20px" }}>
          <img src="/13/whr/whr-southohioregionalshakespeareco-logo.svg" alt="South Ohio Regional Shakespeare Co Logo" />
          <div className="text5">
            Founded in conjunction with the White Hilt Renaissance Faire, the
            SORSC has been staging the works of Shakespeare, Christopher
            Marlowe, and other repertory works for 13 years. Performing out of
            the Tall Oaks Playhouse, Hank and Lynn have directed their own
            celebrated adaptations of Twelfth Night, A Midsummer Night’s Dream,
            and a marathon performance of Henry VI Parts 1, 2 & 3, along with
            many more. The Company also maintains close ties to the local public
            school system, donating costumes, props, time, and direction to the
            budding thespians of Tall Oaks Einstein High School.
            <br></br>
            <br></br>
            <b>Featuring…</b>
            <br></br>
            <br></br>
            <b>Hank Maartens</b> — Credits include A Grape in the Moonlight, How
            to Succeed in Business without Really Trying, A Funny Thing Happened
            on the Way to the Forum, and a celebrated stage adaptation of Duel.
            Hank discovered Shakespearian theater through Lynn, whom he met one
            night at an improv class at the famous Cadence Theatre in New York
            City.
            <br></br>
            <br></br>
            <b>Lynn Maartens</b> — A true triple threat, Lynn is a multifaceted
            actor who has appeared professionally on stage in a wide variety of
            productions: Wicked, The Tragical History of Dr. Faustus, The
            Phantom of the Opera, Macbeth, and The Piano Lesson, to name a few.
            She has also held nearly every job in the theater business, and her
            immense experience fuels the beating heart of the SORSC.
            <br></br>
            <br></br>
            <b>Chester Russo</b> — Actor and writer extraordinaire, you’ll know
            Chester as the mad genius behind the cult hit film Angels in the
            Gutter. Chester Russo is the creative powerhouse behind the White
            Hilt, and has lately been bitten by the acting bug himself.
            <br></br>
            <br></br>
            <b>Cameron Dowe</b> — Formerly the lead singer of local metal
            legends Goat Midwinter, Cameron has struck out on his own with a
            solo career that pushes the bounds of his creative repertoire to new
            frontiers. Catch him on the Main Stage performing his
            historically-influenced ballads and barn-burners.
            <br></br>
            <br></br>
            <b>Danny Bridges</b> — You may remember Danny by his nickname, Toll
            Booth, from when he was a star defensive lineman for East Fork
            College. After snapping both Achilles tendons in a dramatic overtime
            victory to bring the Bullfrogs their first championship trophy,
            Danny took up acting and fell in love with the theater.
            <br></br>
            <br></br>
            <b>Morgan Mattory</b> — Champion pumpkin farmer, Morgan also runs a
            series of urban community gardens around Ohio. Morgan got involved
            in the Faire when he saw it as an opportunity to sell produce and
            educate people about food deserts, but now enjoys time on the stage
            as well.
            <br></br>
            <br></br>
            <b>Sam Langstar</b> — Since the early days of White Hilt, Sam has
            guided set decoration around the Faire to bring the Maartens’ vision
            to life. You can often spot her taking notes in the back row during
            a performance or eating an apple near the fruit hawker’s cart.
            <br></br>
            <br></br>
            <b>Vivian Scotts</b> — A more recent addition to the troupe, some
            playgoers may recognize Miss Scotts as Tall Oaks Einstein High’s
            resident art teacher, who also helps design many of the sets and
            costumes for the shows.
          </div>
        </div>
      </div>
    </div>
  );
}
