import React, { useContext, useState, useEffect } from "react";
import { GameStateContext } from "../../context/context";
import { getComponentTag } from "../../_shared/utils";
import { base_url, icons } from "./../../_shared/config";
import { chatFlow } from "../../_shared/qna";

import {
  useWindowSize,
  qna,
  replaceTokens,
  getSavedMessages,
  saveMessages,
} from "../../_shared/utils";
import AudioPlayer from "react-h5-audio-player";

import {
  addUserMessage,
  addResponseMessage,
  isWidgetOpened,
  toggleMsgLoader,
  renderCustomComponent,
  Widget,
  markAllAsRead,
} from "react-chat-widget";
import "react-chat-widget/lib/styles.css";

import "./desktop.css";
import "./rcw.css";

const Desktop = ({ config, showContact, parentRenderProps }) => {
  console.log("Desktop", config);
  const size = useWindowSize();
  const [current, setCurrent] = useState(null);
  const [fullScreen, setFullScreen] = useState(false);
  const [iconsObj, setIconsObj] = useState(config);
  const context = useContext(GameStateContext);
  const episode = parseInt(context?.getState().episode);

  const toggleLoader = () => {
    toggleMsgLoader();
    //scrollChat();
  };

  const scrollChat = () => {
    const chatContainer = document.getElementsByClassName(
      "rcw-messages-container"
    )[0];

    if (chatContainer) chatContainer.scrollTop = chatContainer.scrollHeight;
  };

  const getEpisodeMessages = () =>
    getSavedMessages().filter((m) => m.episode === episode);

  const addDelayedResponseMessage = (message, index) => {
    sleep(100 * index).then(() => {
      addResponseMessage(message);
    });
  };

  const s3_base = "https://cdn.huntakiller.com/huntakiller/s13/";

  const MessageAudio = ({ filename }) => {
    return (
      <AudioPlayer
        style={{ width: "65%", marginBottom: "15px" }}
        src={s3_base + filename}
      />
    );
  };

  const MessageVideo = ({ filename }) => {
    return (
      <video style={{ width: "65%", marginBottom: "15px" }} controls>
        <source src={s3_base + filename} />
        This browser does not support the HTML5 video element.
      </video>
    );
  };

  const MessageImage = ({ filename }) => {
    return (
      <div
        onClick={() =>
          setCurrent({
            label: filename,
            type: "pdf",
            child: false,
            fullScreen: false,
            url: [filename],
          })
        }
      >
        <img
          style={{
            width: "65%",
            marginBottom: "15px",
            marginLeft: "15px",
            cursor: "pointer",
          }}
          src={s3_base + filename}
          alt={filename}
        />
      </div>
    );
  };

  const sleep = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  const chatImg =
    size.width > 800
      ? "/13/chat-bubble-desktop.svg"
      : "/13/chat-bubble-mobile.svg";

  const close = () => {
    setCurrent(null);
    setFullScreen(false);
    parentRenderProps();
  };

  const iconClick = (icon) => {
    setCurrent(icon);
    if (icon.fullScreen) {
      setFullScreen(true);
    }
    //localstorage state change
    let newIcons = localStorage.getItem("iconState")
      ? JSON.parse(localStorage.getItem("iconState"))
      : icons;
    let updatedIcons = newIcons.map((eachicon, i) => {
      if (icon.child && eachicon.children) {
        let childrens = eachicon.children.map((eachchildren, i) => {
          eachchildren =
            eachchildren.label === icon.label
              ? { ...eachchildren, showReadState: false }
              : eachchildren;
          return eachchildren;
        });
        eachicon = { ...eachicon, children: childrens };
      } else {
        eachicon =
          eachicon.label === icon.label
            ? { ...eachicon, showReadState: false }
            : eachicon;
      }
      return eachicon;
    });
    // file state change
    let localStateIcons = iconsObj.map((localIcon, i) => {
      localIcon =
        localIcon.label === icon.label
          ? { ...localIcon, showReadState: false }
          : localIcon;
      return localIcon;
    });
    setIconsObj(localStateIcons); // current page render
    localStorage.setItem("iconState", JSON.stringify(updatedIcons));
  };

  const getIcon = (icon) => {
    if (icon.unlockAt <= episode) {
      return (
        <li
          key={icon.label}
          onClick={() => iconClick(icon)}
          className="col d-flex justify-content-left"
          style={{ maxWidth: "18vh" }}
        >
          <div className="pt-3 pb-2 hak-icon">
            <img src={icon.img} alt={icon.label} />
            {icon.showReadState && <span className="read-icon"></span>}
          </div>
        </li>
      );
    }
  };

  const saveUserMessage = (message, chatId) => {
    const savedMessages = getEpisodeMessages();
    savedMessages.push({
      type: "user",
      message,
      episode,
      id: chatId,
    });
    saveMessages(savedMessages);
  };

  const saveMediaMessage = (type, mediaId, chatId) => {
    const savedMessages = getEpisodeMessages();
    savedMessages.push({
      type,
      mediaId,
      episode,
      id: chatId,
    });
    saveMessages(savedMessages);
  };

  const saveResponseMessage = (message, chatId) => {
    const savedMessages = getEpisodeMessages();
    savedMessages.push({
      type: "response",
      message,
      episode,
      id: chatId,
    });
    saveMessages(savedMessages);
  };

  useEffect(() => {
    showContact && hydrateChatBox();
  });

  //config is passed from <Landing />
  useEffect(() => {
    setIconsObj(config);
  }, [config]);

  const hydrateChatBox = () => {
    let delay = 50; //ms
    const savedMessages = getEpisodeMessages();

    //start ids for chat for each episode (could be queried?)
    //!!! if qna ids change in qna.js, these values and corresponding qna maker episode metadata should be updated as well!!!
    const episode_start = { 1: 1, 2: 13, 3: 21, 4: 23, 5: 32, 6: 37 };

    //if no saved messages for this episode, get greetings to start chatFlow
    if (savedMessages.length === 0) {
      toggleLoader();
      getNextChat(episode_start[episode]);
    } else {
      savedMessages.forEach((msg, index) => {
        if (msg.type === "user") {
          setTimeout(() => addUserMessage(msg.message), delay * index);
        } else if (msg.type === "response") {
          setTimeout(() => addResponseMessage(msg.message), delay * index);
        } else if (msg.type === "audio") {
          setTimeout(
            () =>
              renderCustomComponent(MessageAudio, { filename: msg.mediaId }),
            delay * index + 60
          );
        } else if (msg.type === "video") {
          setTimeout(
            () =>
              renderCustomComponent(MessageVideo, { filename: msg.mediaId }),
            delay * index + 60
          );
        } else if (msg.type === "image") {
          setTimeout(
            () =>
              renderCustomComponent(MessageImage, { filename: msg.mediaId }),
            delay * index + 60
          );
        }

        if (savedMessages.length === index + 1)
          setTimeout(markAllAsRead, delay * index + 1);
      });
    }
  };

  const getNextChat = (chatStartId) => {

    if (chatStartId == 35) { 
      chatStartId++; 
      //console.log("new currentChatId: " + chatStartId);
    }


    let chat_filtered_sorted = chatFlow
      .filter((c) => c.episode === episode)
      .sort((a, b) => a.id - b.id);

    let delay = 0;

    for (
      let i = chatStartId;
      i < chatStartId + chat_filtered_sorted.length;
      i++
    ) {
      let chat = chat_filtered_sorted.find((c) => c.id == i);

      delay = chat.delay;

      if (chat.end_of_episode) {
        sleep(delay).then(() => {
          //console.log(delay);
          if (chat.msg != "") {
            addResponseMessage(chat.msg);
            saveResponseMessage(chat.msg, chat.id);
          }

          if (chat.attachment && chat.attachment.length) {
            getAttachment(chat, chat.id);
          }
          toggleLoader();
          scrollChat();
        });

        break;
      } else if (chat.answer_required) {
        sleep(delay).then(() => {
          //(chat, delay);
          let chat_msg = chat.msg;

          if (chat_msg != "") {
            if (chat_msg != "[hide]") {
              addResponseMessage(chat_msg);
            }
            saveResponseMessage(chat_msg, chat.id);
          }

          if (chat.attach_before_reponse) {
            //ep1 special case
            sleep(10000).then(() => {
              getAttachment(chat, chat.id);
              scrollChat();
            });
          }

          toggleLoader();
          scrollChat();
        });

        //break if answer_required, don't continue showing messages, instead accept a user input
        break;
      } else {
        sleep(delay).then(() => {
          //console.log(delay);
          if (chat.msg != "") {
            addResponseMessage(chat.msg);
            saveResponseMessage(chat.msg, chat.id);
          }
          if (chat.attachment && chat.attachment.length) {
            getAttachment(chat, chat.id);
          }
          scrollChat();
        });
      }
    }
  };

  //note: correct answers are stored in qna maker
  //chat messages appear sequentially based on id
  //if a chat line expects an answer, a combination of episode contacted with chat id will be used as key to fetch correct answer from qna
  //if correct answser matches user input next chat is shown to user basd on chat id
  //if answer is incorrect, the wrong_answer value is used to display message to player
  const handleNewUserMessage = async (newMessage) => {
    try {
      const savedMessages = getEpisodeMessages();

      let currentChatId = savedMessages.length
        ? savedMessages[savedMessages.length - 1].id
        : 1;

      let currentChat = chatFlow.find((c) => c.id === currentChatId);

      if (currentChat.end_of_episode) {
        return;
      }

      saveUserMessage(newMessage, currentChatId);

      //console.log("currentChatId: " + currentChatId, newMessage);
      if (currentChatId == 34) { 
        currentChatId++; 
        //console.log("new currentChatId: " + currentChatId, newMessage);
      }

      let qna_response = await qna(newMessage, episode, currentChatId);
      const data = await qna_response.json();

      const response = data?.answers[0]?.answer;
      if (response.toLowerCase() === "no good match found in kb.") {
        let wrong_anwer_resp =
          currentChat.wrong_answer[newMessage.trim().toLowerCase()] ||
          currentChat.wrong_answer["any_other"];
        addDelayedResponseMessage(wrong_anwer_resp, 10);
        saveResponseMessage(wrong_anwer_resp, currentChatId);
      } else if (response.toLowerCase().indexOf("{{incorrect}}") !== -1) {
        saveResponseMessage(replaceTokens(response), currentChatId);
        addDelayedResponseMessage(replaceTokens(response), 10);
      } else {
        toggleLoader();
        getNextChat(currentChatId + 1);
      }
    } catch (error) {
      //console.log(error);
      addDelayedResponseMessage(
        "I’m not at the computer right now, sorry!",
        10
      );
    }
  };

  function getAttachment(currentChat, currentChatId) {
    currentChat.attachment.forEach((cc) => {
      if (cc.type === "audio") {
        renderCustomComponent(MessageAudio, { filename: cc.name });
        saveMediaMessage("audio", cc.name, currentChatId);
      }
      if (cc.type === "video") {
        renderCustomComponent(MessageVideo, { filename: cc.name });
        saveMediaMessage("video", cc.name, currentChatId);
      }
      if (cc.type === "image") {
        renderCustomComponent(MessageImage, { filename: cc.name });
        saveMediaMessage("image", cc.name, currentChatId);
      }
    });

    scrollChat();
  }

  return (
    <>
      {!fullScreen ? (
        <>
          {showContact && (
            <div onClick={() => isWidgetOpened()}>
              <Widget
                handleNewUserMessage={handleNewUserMessage}
                title="Art"
                subtitle={null}
                senderPlaceHolder={"New Message"}
                launcherOpenImg={base_url + chatImg}
              />
            </div>
          )}
          <div
            style={{
              maxHeight: "80%",
              maxWidth: episode < 5 ? "800px" : "", //HUNTAKLR13-190
              overflowY: "auto",
              //display: "flex",
              //justifyContent: "space-between",
              //flexWrap: "wrap",
            }}
          >
            <ul className="row hak-icon-ul">
              {iconsObj.map((icon) => getIcon(icon))}
              {current && getComponentTag(current, close)}
            </ul>
          </div>
        </>
      ) : (
        current && getComponentTag(current, close)
      )}
    </>
  );
};

export default Desktop;
