//delay resets to 3500 after anwer_requird = true
//delay for attachments is 10000
export const chatFlow = [
    {
        id: 1, episode: 1, delay: 3500, msg: `Hey, this is a list of a few strange events at the faire before Hank’s murder. I believe…
    - A pair of spurs was left on Hank’s car.
    - Someone built a scarecrow outside the faire with a threatening message.
    - There was an incident with a swapped sword that caused Hank to get injured just a few hours before his murder.` },
    { id: 2, episode: 1, delay: 7000, msg: `I already suspect Lynn; Hank’s wife unquestionably stands to gain the most from his death, but let’s figure out who else had a grudge, or stood to gain something. We’ll start with the spurs. Leaving spurs was considered a challenge among knights. Let’s see if we can figure out who left these for Hank.` },
    { id: 3, episode: 1, delay: 10500, msg: `Who left the spurs?`, answer_required: true, attachment: [], wrong_answer: { any_other: "I’m not sure it was them. Not to say they’re not suspicious, but I don’t think they placed the spurs." } },
    { id: 4, episode: 1, delay: 3500, msg: "Yes, that checks out. He was training to be a knight, and didn’t seem too happy about being made the jester. But he was talking to someone else, too… who else was in on that spurs plan?", answer_required: true, attachment: [], wrong_answer: { any_other: "I don’t think Danny has much of a relationship with them." } },
    { id: 5, episode: 1, delay: 7000, msg: "I agree. Cameron and Danny are good friends. It looks like we have our first two suspects." },
    { id: 6, episode: 1, delay: 10000, msg: "OK, on to the scarecrow. Here is a photo, as well as a transcription of the message that was on it. Let’s figure out who could have written the message on that.", answer_required: true, attach_before_reponse: true, attachment: [{ type: "image", name: "scarecrow-photo.jpg" }], wrong_answer: { any_other: "I’m not sure it was them. Try seeing who had a connection to the scarecrow, and who could have placed it." } },
    { id: 7, episode: 1, delay: 3500, msg: "Vivian placing the scarecrow makes sense. That’s very much her aesthetic. But what doesn’t make sense is I can’t figure out her connection to that plot of land. Let’s figure out who could have easily accessed that plot. Whoever that person is, they must have helped Vivian place the scarecrow.", answer_required: true, attachment: [], wrong_answer: { any_other: "I don’t think that’s right. Try seeing who had a connection to the plot of land the scarecrow appeared on, and we’ll have another suspect." } },
    { id: 8, episode: 1, delay: 7000, msg: "Hmm… I knew that plot of land was between the faire and his home, and that Morgan was interested in it, but I didn’t know Hank was involved as well. There seems to be bad blood there, so let’s add Morgan to the list." },
    { id: 9, episode: 1, delay: 10000, msg: "Last thing: the case of the swapped sword. Hank was injured in a stunt just before his death. I believe it’s what made people feel comfortable blaming Sam for what happened. Hank was struck during one of our rehearsals, but Sam would never be so careless as to use a real, sharpened blade. Someone deliberately swapped a sharp stand-in sword for the “hero” version." },
    { id: 10, episode: 1, delay: 13000, msg: "Who could have done such a thing?", answer_required: true, attachment: [], wrong_answer: { any_other: "Try seeing if anyone had something to do with swords or the stage." } },
    { id: 11, episode: 1, delay: 3500, msg: "Chester. I see where he’s noted the sword elsewhere. He’s always been one for hyper-realism. Often to the point of impracticality. Sometimes to the point of dangerousness." },
    { id: 12, episode: 1, delay: 7000, msg: "And with that, we have our suspect list: Lynn, Danny, Cameron, Vivian, Morgan, and Chester. All these people had a grudge against Hank, or stood to gain something from his death. I’m going to go look for more evidence concerning our new set of suspects, and I’ll send you another package with what I find.", end_of_episode: true, },
    {
        answer_required: true,
        attachment: [],
        delay: 3500,
        episode: 2,
        id: 13,
        msg: "Now that we have some official documentation on the case, is there anyone we can definitively rule out?",
        wrong_answer: {
            any_other: "I don’t think that checks out. There must be something we’re forgetting or overlooking."
        }
    }, {
        answer_required: false,
        attachment: [],
        delay: 3500,
        episode: 2,
        id: 14,
        msg: "I followed up on your advice of Morgan, and it looks like you were right. He was in the faire at the time, but he’s not Hank’s killer.",
        wrong_answer: {
            any_other: ""
        }
    }, {
        answer_required: false,
        delay: 7000,
        attachment: [],
        episode: 2,
        id: 15,
        msg: "I recorded our conversation. Check out what he had to say.",
        wrong_answer: {
            any_other: ""
        }
    },
    {
        answer_required: false,
        attachment: [{ type: "video", name: "morgans-true-story-video.mp4" }],
        delay: 20500,
        episode: 2,
        id: 16,
        msg: "",
        wrong_answer: {
            any_other: ""
        }
    },
    {
        answer_required: true,
        attachment: [],
        delay: 30000,
        episode: 2,
        id: 17,
        msg: "If Morgan passed by the forge at midnight and Hank’s body wasn’t there, I think that means the forge wasn’t actually the scene of the murder. Hank must’ve been killed somewhere else and then moved. If we can figure out that location, we might be able to find another clue. Message me when you think you know the location.",
        wrong_answer: {
            any_other: "No, no, I don’t think that’s it. I just checked that area out and wasn’t able to find anything.",
            "tavern": "We have multiple taverns at our faire. Which one should I investigate?",
            "jousting pit": "I’m not sure that’s where the murder happened. I checked the pit and couldn’t find any clues. Perhaps he was moved through here? Try to figure out where the murder happened and I’ll check there for evidence.",
            "jousting arena": "I’m not sure that’s where the murder happened. I checked the arena and couldn’t find any clues. Perhaps he was moved through here? Try to figure out where the murder happened and I’ll check there for evidence.",
        }
    }, {
        answer_required: false,
        attachment: [],
        delay: 3500,
        episode: 2,
        id: 18,
        msg: "Hank’s favorite tavern. That’s the scene of the murder. He must have gone there after we all closed up.",
        wrong_answer: {
            any_other: ""
        }
    }, {
        answer_required: false,
        attachment: [],
        delay: 7000,
        episode: 2,
        id: 19,
        msg: "I'm gonna check it out, and see if I can find anything.",
        wrong_answer: {
            any_other: ""
        }
    },
    {
        answer_required: false,
        attachment: [{ type: "video", name: "bloody-costume-video.mp4" }],
        delay: 17000,
        episode: 2,
        id: 20,
        msg: "",
        wrong_answer: {
            any_other: ""
        },
        end_of_episode: true,
    },
    {
        answer_required: true,
        attachment: [],
        delay: 3500,
        episode: 3,
        id: 21,
        msg: "Getting all this information was a lot easier with Morgan on our team. Is there anyone else we can clear and hopefully get as our ally?",
        wrong_answer: {
            any_other: "I’m not so sure they’re innocent."
        }
    }, {
        answer_required: false,
        attachment: [],
        delay: 3500,
        episode: 3,
        id: 22,
        msg: `Danny being innocent checks out. At least of Hank’s murder. It looks like at the time he was sabotaging Chester’s outfit for the joust. I’m thinking he messed with the costume because Chester had a hand in changing his role. The latex gave him an allergic reaction, so he went to urgent care shortly after. \n\n This means the murderer must have been wearing the Green Knight costume when they killed Hank. That’s the only other one that could fit the extra pieces that were found at the scene. I’ll try to track down the costume, and I’ll send you a package if I find anything else interesting.`,
        wrong_answer: {
            any_other: ""
        },
        end_of_episode: true,
    }, {
        answer_required: true,
        attachment: [],
        delay: 3500,
        episode: 4,
        id: 23,
        msg: "The other volunteers were planning something without me. Where can I go to find more information?",
        wrong_answer: {
            any_other: "I’m not sure if that’s the right place."
        }
    }, {
        answer_required: true,
        attachment: [],
        delay: 3500,
        episode: 4,
        id: 24,
        msg: "Anarchy, that’s a secure chatting app. With Anarchy, you can have multiple private groups. If the other volunteers were talking there, they would have had a group name. Find the name of that group and I’ll search for it.",
        wrong_answer: {
            any_other: "I don’t think that’s the right name for the chat. I tried your suggestion and there was nothing there."
        }
    }, {
        answer_required: true,
        attachment: [],
        delay: 3500,
        episode: 4,
        id: 25,
        msg: "Manticore is the name of the group chat, alright. It’s password protected, so I know we’re on to something. Figure out what the password is and send it to me.",
        wrong_answer: {
            any_other: "That’s not the right password."
        }
    }, {
        answer_required: false,
        attachment: [],
        delay: 3500,
        episode: 4,
        id: 26,
        msg: "That password worked! I was able to get inside the group chat and take a screenshot of what I found, including an attached document of some sort of meeting minutes. Judging by the time stamp, whoever sent this document was clearly there at this meeting and not at the scene of the murder.",
        wrong_answer: {
            any_other: ""
        }
    },
    {
        answer_required: false,
        attachment: [{ type: "image", name: "group-chat-screenshots.jpg" }],
        delay: 10000,
        episode: 4,
        id: 27,
        msg: "",
        wrong_answer: {
            any_other: ""
        }
    },
    {
        answer_required: false,
        attachment: [{ type: "image", name: "meeting-notes-4.jpg" }],
        delay: 20000,
        episode: 4,
        id: 28,
        msg: "",
        wrong_answer: {
            any_other: ""
        }
    },
    {
        answer_required: true,
        attachment: [],
        delay: 30000,
        episode: 4,
        id: 29,
        msg: "If we can figure out the true identity of the Hierophant, we can clear them of the murder. Message me again when you know who it is.",
        wrong_answer: {
            any_other: "I’m not so sure if they’re the Hierophant, detective. "
        }
    }, {
        answer_required: false,
        attachment: [],
        delay: 3500,
        episode: 4,
        id: 30,
        msg: "Vivian’s the Hierophant. Her being the organizer of all this really makes sense. I never had her as a teacher, but from what I heard from classmates over the years, organizing a group of workers seems just like something she’d do. I’ve corroborated from other people listed in that document that she was there taking notes. I’m going to go see if I can get any more information from her.",
        wrong_answer: {
            any_other: ""
        }
    },
    {
        answer_required: false,
        attachment: [],
        delay: 7000,
        episode: 4,
        id: 31,
        msg: "I’ll send you another package with any evidence I come across.",
        wrong_answer: {
            any_other: ""
        },
        end_of_episode: true,
    },
    {
        answer_required: true,
        attachment: [],
        delay: 3500,
        episode: 5,
        id: 32,
        msg: "The passcode to the keypad should be four digits, but that’s all I know. Check out what I sent and see if you can find the code.",
        wrong_answer: {
            any_other: "That didn’t do it. I’m still waiting nearby. Send me another code, and I’ll try again."
        }
    },
    {
        answer_required: false,
        attachment: [{ type: "video", name: "royal-lounge-video.mp4" }],
        delay: 10000,
        episode: 5,
        id: 33,
        msg: "",
        wrong_answer: {
            any_other: ""
        }
    },
    {
        answer_required: false,
        attachment: [{ type: "image", name: "chesters-script-page.jpg" }],
        delay: 70000,  //to show this after video is done playing
        episode: 5,
        id: 34,
        msg: "",
        wrong_answer: {
            any_other: "That combination didn’t work. Give it another try1."
        }
    },
    {
        answer_required: true,
        attachment: [],
        delay: 10000,
        episode: 5,
        id: 35,
        msg: "[hide]", //[What is the passcode to the treasure chest found in the lounge?]
        wrong_answer: {
            any_other: "That combination didn’t work. Give it another try."
        }
    },
    {
        answer_required: false,
        attachment: [{ type: "video", name: "treasure-chest-video.mp4" }],
        delay: 10000,
        episode: 5,
        id: 36,
        msg: "",
        wrong_answer: {
            any_other: ""
        },
        end_of_episode: true
    }, 
    {
        answer_required: true,
        attachment: [],
        delay: 3500,
        episode: 6,
        id: 37,
        msg: "We have to hurry and find Hank’s killer before they leave town. The true identity of the Green Knight is either Chester, Lynn, or Cameron.",
        wrong_answer: {
            any_other: "We narrowed it down to Chester, Lynn or Cameron. It has to be one of them. Take another look and let me know which one of them is the Hank's killer.",
            "chester": "We need something completely undeniable if we’re going to accuse a murderer. I’m not so sure that person is Chester. Take another look at everything and message me when you figure it out.",
            "lynn": "I don’t think it was Lynn. Sure, she stands to gain a ton, but her being the killer just isn’t airtight."
        }
    },
    {
        answer_required: false,
        attachment: [],
        delay: 3500,
        episode: 6,
        id: 38,
        msg: "Cameron… All the evidence points to him; the Green Knight mask was left in his apartment’s trash. He had access to the royal lounge to write the letters. He even covered up the burn scar he must have received when he was disposing of evidence with a tattoo.",
        wrong_answer: {
            any_other: ""
        }
    }, {
        answer_required: false,
        attachment: [],
        delay: 7000,
        episode: 6,
        id: 39,
        msg: "He killed Hank so he could clear the path for him and Lynn to be together. But though she doesn’t seem to have known Cameron killed him, it looks like Lynn didn’t feel the same way.",
        wrong_answer: {
            any_other: ""
        }
    },
    {
        answer_required: false,
        attachment: [],
        episode: 6,
        delay: 10000,
        id: 40,
        msg: "I’ve called the police. They’re on their way right now. I’m going to record what happens.",
        wrong_answer: {
            any_other: ""
        }
    },
    {
        answer_required: false,
        attachment: [{ type: "video", name: "finale-video.mp4" }],
        episode: 6,
        delay: 17000,
        id: 41,
        msg: "",
        wrong_answer: {
            any_other: ""
        }
    },
    {
        answer_required: false,
        attachment: [{ type: "video", name: "exoneration-video.mp4" }],
        episode: 6,
        delay: 39000,
        id: 42,
        msg: "",
        wrong_answer: {
            any_other: ""
        },
        end_of_episode: true,
    }
]