import React from "react";

export default function Troy() {
  return (
    <div className="homeContainer">
      <div>
        <img
          style={{ padding: "30px" }}
          src="/13/whr/whr-danielandalicetroyfoundation-logo.svg"
          alt="Daniel and Alice Troy Foundation"
        />
        <div className="text3">
          Beloved friends of the Maartens and early supporters of White Hilt,
          Daniel and Alice Troy encouraged Hank Maartens to follow his dream and
          create a world for people to come together and enjoy the art and
          culture of another age. Each year, a portion of the Faire’s proceeds
          go directly to the late couple’s posthumous philanthropic foundation.
          The Daniel and Alice Troy Foundation has funded significant
          advancements in on-site emergency medical treatment and research for
          nearly a decade. The foundation’s work has supported giving emergency
          medical personnel the resources to help those who just ten years ago
          would have died from injuries related to vehicle collisions, saving
          hundreds of lives. Thank you, Daniel and Alice, for your legacy.
        </div>
      </div>
    </div>
  );
}
