import React from "react";

const s3_base = "https://cdn.huntakiller.com/huntakiller/s13/";

export default function Attractions() {
  return (
    <div className="homeContainer">
      <div>
        <img src={s3_base + "whr-attractions-map.jpg"} alt="Attractions Map" />
        <div style={{ padding: "20px" }}>
          <img src={s3_base + "whr-attractions-smithing-image.jpg"} alt="Attractions Smithing" />
          <div className="text1 text4">
            <span className="text6">Sam’s Smith</span> <br />
            Take classes on real smithing techniques or buy your own blade.
          </div>
          <img src={s3_base + "whr-attractions-marketsquare-image.jpg"} alt="Attractions Market Square" />
          <div className="text1 text4">
            <span className="text6">The Market Square</span> <br />
            Sample a king’s ransom in handsome wares. From magical talismans to
            royal quality dress, you’re sure to find what you’re seeking here.
          </div>
          <img src={s3_base + "whr-attractions-mainstage-image.jpg"} alt="Attractions Main Stage" />
          <div className="text1 text4">
            <span className="text6">The Main Stage</span> <br />
            Catch a part of this season’s story or settle in for a night of
            music from our resident Bard, Cameron Dowe (the former lead singer
            of popular local band Goat Midwinter).
          </div>
          <img src={s3_base + "whr-attractions-joustingpit-image.jpg"} alt="Attractions Jousting Pit" />
          <div className="text1 text4">
            <span className="text6">The Jousting Pit</span> <br />
            See our brave knights on horseback as they clash for the honor of
            the Kingdom.
          </div>
          <img src={s3_base + "whr-attractions-medievalmuseum-image.jpg"} alt="Medieval Museum" />
          <div className="text1 text4">
            <span className="text6">The Medieval Museum</span> <br />
            Learn what life in the Middle Ages was really like and see genuine
            artifacts from the days of chivalry.
          </div>
        </div>
      </div>
    </div>
  );
}
