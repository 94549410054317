import React from "react";

export default function Founders() {
  return (
    <div className="homeContainer">
      <div>
        <img
          className="center"
          style={{ width: "80%", marginBottom: "40px" }}
          src="/13/whr/whr-ourfounders-logo.svg"
          alt="Our Founders"
        />
        <img
          className="center"
          style={{ width: "60%", marginBottom: "40px" }}
          src="/13/whr/whr-13th-logo.svg"
          alt="13th season logo"
        />
        <img
          className="center"
          style={{ width: "60%", marginBottom: "40px" }}
          src="/13/whr/whr-divider.svg"
          alt="divider"
        />
        <div className="text3">
          This year will mark the White Hilt’s 13th season, and in those years
          we have grown from a small passion project to a Midwest cultural
          institution with attendance in the thousands. The White Hilt Renfaire
          was the creative child of founders Hank and Lynn Maartens, former
          heavyweights of the Broadway stage. The two met in a musical
          adaptation of Shakespeare’s The Life and Death of King Richard II at
          the Cadence Theatre where they both played the leads. Bonding over
          their shared love of theater and medieval European history, the two
          were soon married at New York’s City Hall.
        </div>
        <div className="text3">
          Hank, a native of Tall Oaks, was given the once-in-a-lifetime
          opportunity to buy the former county fairgrounds in his hometown, and
          he convinced Lynn to move with him back to Ohio to start their own
          theater company. Lynn agreed on one condition: that in the summer, she
          and Hank would organize a yearly Renaissance festival, and the White
          Hilt Renaissance Faire was born.
        </div>
      </div>
    </div>
  );
}
