import React, { useContext, useState } from 'react'
import { Header } from './ModalHeader';
import './viewer.css';
import Draggable from 'react-draggable'
import Password from './Password'
import { GameStateContext } from '../../context/context';

const Viewer = ({ icon, close }) => {

    const s3_base = "https://cdn.huntakiller.com/huntakiller/s13/"
    const { getState, saveEp5LockedVideo } = useContext(GameStateContext);
    let { episode, ep5LockedVideo } = getState();
    const nodeRef = React.useRef(null);
    const [pass, setPass] = useState((episode >= 6 || ep5LockedVideo) ? true : false)

    const handleSetPass = (isValid) => {
        setPass(isValid);
        icon.label === "Locked Video" && episode == "5" && saveEp5LockedVideo(isValid);
    }

    if (icon.label === "Locked Video" && !pass) {
        return <Password setPassed={handleSetPass} close={close} />
    }
    else return (<Draggable
        nodeRef={nodeRef}
        handle={icon.child ? ".hak-modal-header-label-secondlevel" : ".hak-modal-header-label"}
        bounds={icon.child ? "#folder-bounds" : "#draggable-bounds"}>
        <div ref={nodeRef} className={icon.label === "Locked Video" ? "hak-modal-video-contact" : "hak-modal-video"}>
            <Header icon={icon} close={close} secondLevel={Boolean(icon.child)} />
            <video controls width="100%" height="95%" >
                <source src={s3_base+icon.url} />
                This browser does not support the HTML5 video element.
            </video>
        </div>
    </Draggable>
    )
}

export default Viewer;