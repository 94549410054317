import Div100vh from "react-div-100vh";
import { useState, useContext } from "react";
import { GameStateContext } from "./../context/context";
import "./desktop/desktop.css";

const Login = () => {
  const [isError, setIsError] = useState(false);
  const passes = JSON.parse(process.env.REACT_APP_EP_PW);

  const [pw, setPw] = useState("");
  const context = useContext(GameStateContext);

  const handleChange = (event) => {
    setIsError(false);
    setPw(event.target.value);
  };

  const setEpisode = (e) => {
    let episode = passes[pw.toUpperCase()];
    if (!episode) {
      setIsError(true);
      e.preventDefault();
      return;
    } else {
      context?.updateState({ episode: episode });
      window.location.href = "/";
    }
  };

  const handleSubmit = (e) => {
    if (!pw) {
      setIsError(true);
      e.preventDefault();
      return;
    }
    setEpisode(e);
  };

  return (
    <Div100vh
      dontresize="true"
      className="vh-100 landingContainer gray-logo-background"
    >
      <form onSubmit={(e) => handleSubmit(e)}>
        <div className={"landingLoginBox rounded"}>
          <div className={"d-flex justify-content-center p-3"}>
            <img
              src="https://cdn.huntakiller.com/huntakiller/s7/logo.png"
              alt="logo"
            />
          </div>
          <div className={"d-flex justify-content-center"}>
            <div>
              <input
                type="password"
                className="shadow appearance-none border  w-full py-2 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                value={pw}
                onChange={handleChange}
                autoFocus
                id="pw"
                placeholder="Enter Password"
              />
              <div className={"d-flex justify-content-center p-3"}>
                <button
                  className="w-full text-black font-bold py-1 px-4 rounded focus:outline-none focus:shadow-outline"
                  id="episodeLogin"
                  onClick={(e) => handleSubmit(e)}
                >
                  ENTER
                </button>
              </div>
              {isError && (
                <p className="d-flex justify-content-center error">
                  Invalid Password
                </p>
              )}
            </div>
          </div>
        </div>
      </form>
    </Div100vh>
  );
};

export default Login;
