export const base_url = window.location.origin;

export const icons = [
    {
        label: "For Detective",
        type: "folder",
        img: base_url + "/13/for-detective-folder-icon.svg",
        fullScreen: false,
        unlockAt: 1,
        showReadState: true,
        children: [
            {
                label: "Scarecrow Photo",
                type: "pdf",
                img: base_url + "/13/scarecrow-photo-icon.svg",
                child: true,
                fullScreen: false,
                unlockAt: 2,
                url: ["scarecrow-photo.jpg"],
                showReadState: true,
            },
            {
                label: "Morgan's True Story",
                type: "video",
                img: base_url + "/13/morgans-true-story-video-icon.svg",
                child: true,
                fullScreen: false,
                url: "morgans-true-story-video.mp4",
                unlockAt: 3,
                showReadState: true,
            },
            {
                label: "Bloody Costume",
                type: "video",
                img: base_url + "/13/bloody-costume-video-icon.svg",
                child: true,
                fullScreen: false,
                url: "bloody-costume-video.mp4",
                unlockAt: 3,
                showReadState: true,
            },
            {
                label: "Meeting Notes",
                type: "pdf",
                img: base_url + "/13/meeting-notes-4-icon.svg",
                child: false,
                fullScreen: false,
                unlockAt: 5,
                url: ["meeting-notes-4.jpg"],
                showReadState: true,
            },
            {
                label: "Groupchat Screenshots",
                type: "pdf",
                img: base_url + "/13/group-chat-screenshots-icon.svg",
                child: false,
                fullScreen: false,
                unlockAt: 5,
                url: ["group-chat-screenshots.jpg"],
                showReadState: true,
            },
            {
                label: "Royal Lounge Video",
                type: "video",
                img: base_url + "/13/royal-lounge-video-icon.svg",
                child: true,
                fullScreen: false,
                url: "royal-lounge-video.mp4",
                unlockAt: 6,
                showReadState: true,
            },
            {
                label: "Chester's Script Page",
                type: "pdf",
                img: base_url + "/13/chesters-script-page-icon.svg",
                child: true,
                fullScreen: false,
                unlockAt: 6,
                url: ["chesters-script-page.jpg"],
                showReadState: true,
            },
            {
                label: "Treasure Chest Video",
                type: "video",
                img: base_url + "/13/treasure-chest-video-icon.svg",
                child: true,
                fullScreen: false,
                url: "treasure-chest-video.mp4",
                unlockAt: 6,
                showReadState: true,
            },
        ]
    },
    {
        label: "Meeting Minutes",
        type: "pdf",
        img: base_url + "/13/meeting-minutes-1-icon.svg",
        child: true,
        fullScreen: false,
        unlockAt: 1,
        url: ["meeting-minutes-1-pg1.jpg", "meeting-minutes-1-pg2.jpg"],
        showReadState: true,
    },
    {
        label: "Costume Analysis",
        type: "pdf",
        img: base_url + "/13/costume-analysis-icon.svg",
        child: true,
        fullScreen: false,
        unlockAt: 3,
        url: ["costume-analysis.jpg"],
        showReadState: true,
    },
    {
        label: "Good Fences Posts",
        type: "folder",
        img: base_url + "/13/good-fences-posts-folder-icon.svg",
        fullScreen: false,
        unlockAt: 1,
        showReadState: true,
        children: [
            {
                label: "Good Fences",
                type: "pdf",
                img: base_url + "/13/good-fences-icon.svg",
                child: true,
                fullScreen: false,
                unlockAt: 1,
                url: ["good-fences-pg1.jpg", "good-fences-pg2.jpg", "good-fences-pg3.jpg", "good-fences-pg4.jpg"],
                showReadState: true,
            },
            {
                label: "Good Fences (1)",
                type: "pdf",
                img: base_url + "/13/good-fences-1-icon.svg",
                child: true,
                fullScreen: false,
                unlockAt: 2,
                url: ["good-fences-1-pg1.jpg", "good-fences-1-pg2.jpg", "good-fences-1-pg3.jpg"],
                showReadState: true,
            },
            {
                label: "Good Fences (2)",
                type: "pdf",
                img: base_url + "/13/good-fences-2-icon.svg",
                child: true,
                fullScreen: false,
                unlockAt: 3,
                url: ["good-fences-2-pg1.jpg", "good-fences-2-pg2.jpg", "good-fences-2-pg3.jpg", "good-fences-2-pg4.jpg", "good-fences-2-pg5.jpg"],
                showReadState: true,
            },
            {
                label: "Good Fences (3)",
                type: "pdf",
                img: base_url + "/13/good-fences-3-icon.svg",
                child: true,
                fullScreen: false,
                unlockAt: 4,
                url: ["good-fences-3-pg1.jpg", "good-fences-3-pg2.jpg", "good-fences-3-pg3.jpg", "good-fences-3-pg4.jpg"],
                showReadState: true,
            }, {
                label: "Good Fences (4)",
                type: "pdf",
                img: base_url + "/13/good-fences-4-icon.svg",
                child: true,
                fullScreen: false,
                unlockAt: 5,
                url: ["good-fences-4-pg1.jpg", "good-fences-4-pg2.jpg", "good-fences-4-pg3.jpg", "good-fences-4-pg4.jpg"],
                showReadState: true,
            },
            {
                label: "Good Fences (5)",
                type: "pdf",
                img: base_url + "/13/good-fences-5-icon.svg",
                child: true,
                fullScreen: false,
                unlockAt: 6,
                url: ["good-fences-5-pg1.jpg", "good-fences-5-pg2.jpg", "good-fences-5-pg3.jpg", "good-fences-5-pg4.jpg"],
                showReadState: true,
            }
        ]
    },
    {
        label: "Annotated Outline",
        type: "pdf",
        img: base_url + "/13/annotated-outline-1-icon.svg",
        child: false,
        fullScreen: false,
        unlockAt: 1,
        url: ["annotated-outline-1.jpg"],
        showReadState: true,
    },
    {
        label: "Fight Video",
        type: "video",
        img: base_url + "/13/fight-video-icon.svg",
        child: false,
        fullScreen: false,
        url: "fight-video.mp4",
        unlockAt: 1,
        showReadState: true,
    },
    {
        label: "Police Statement Video",
        type: "video",
        img: base_url + "/13/police-statement-video-icon.svg",
        child: false,
        fullScreen: false,
        url: "police-statement-video.mp4",
        unlockAt: 1,
        showReadState: true,
    },
    {
        label: "Prison Recording",
        type: "audio",
        img: base_url + "/13/prison-recording-audio-icon.svg",
        child: false,
        fullScreen: false,
        url: "prison-recording.mp3",
        unlockAt: 1,
        showReadState: true,
    },
    {
        label: "White Hilt",
        type: "whr",
        img: base_url + "/13/renfaire-website-desktop-icon.svg",
        fullScreen: true,
        unlockAt: 1,
        showReadState: true
    },
    {
        label: "White Hilt Faire Email Printout",
        type: "pdf",
        img: base_url + "/13/white-hilt-faire-email-printout-icon.svg",
        child: true,
        fullScreen: false,
        unlockAt: 2,
        url: ["white-hilt-faire-email-printout.jpg"],
        showReadState: true,
    },
    {
        label: "Play Review",
        type: "pdf",
        img: base_url + "/13/play-review-icon.svg",
        child: true,
        fullScreen: false,
        unlockAt: 2,
        url: ["play-review.jpg"],
        showReadState: true,
    },
    {
        label: "Suspect Interviews",
        type: "folder",
        img: base_url + "/13/suspect-interviews-folder-icon.svg",
        fullScreen: false,
        unlockAt: 2,
        showReadState: true,
        children: [
            {
                label: "Vivian Interview",
                type: "video",
                img: base_url + "/13/vivian-interview-video-icon.svg",
                child: true,
                fullScreen: false,
                url: "vivian-interview-video.mp4",
                unlockAt: 2,
                showReadState: true,
            },
            {
                label: "Chester Interview",
                type: "video",
                img: base_url + "/13/chester-interview-video-icon.svg",
                child: true,
                fullScreen: false,
                url: "chester-interview-video.mp4",
                unlockAt: 2,
                showReadState: true,
            },
            {
                label: "Lynn Interview",
                type: "video",
                img: base_url + "/13/lynn-interview-video-icon.svg",
                child: true,
                fullScreen: false,
                url: "lynn-interview-video.mp4",
                unlockAt: 2,
                showReadState: true,
            },
            {
                label: "Morgan Interview",
                type: "video",
                img: base_url + "/13/morgan-interview-video-icon.svg",
                child: true,
                fullScreen: false,
                url: "morgan-interview-video.mp4",
                unlockAt: 2,
                showReadState: true,
            },
            {
                label: "Cameron Interview",
                type: "video",
                img: base_url + "/13/cameron-interview-video-icon.svg",
                child: true,
                fullScreen: false,
                url: "cameron-interview-video.mp4",
                unlockAt: 2,
                showReadState: true,
            },
            {
                label: "Danny Interview",
                type: "video",
                img: base_url + "/13/danny-interview-video-icon.svg",
                child: true,
                fullScreen: false,
                url: "danny-interview-video.mp4",
                unlockAt: 2,
                showReadState: true,
            },
        ]
    },
    {
        label: "Magic Show Photos",
        type: "folder",
        img: base_url + "/13/magic-show-photos-folder-icon.svg",
        fullScreen: false,
        unlockAt: 3,
        showReadState: true,
        children: [{
            label: "Magic Show Photo",
            type: "pdf",
            img: base_url + "/13/magic-show-photos-icon.svg",
            child: true,
            fullScreen: false,
            unlockAt: 3,
            url: ["magic-show-photo.jpg"],
            showReadState: true,
        }]
    },

    {
        label: "Incident Reports",
        type: "pdf",
        img: base_url + "/13/incident-reports-icon.svg",
        child: false,
        fullScreen: false,
        unlockAt: 3,
        url: ["incident-reports-pg1.jpg", "incident-reports-pg2.jpg", "incident-reports-pg3.jpg"],
        showReadState: true,
    },
    {
        label: "Cancellation Poster",
        type: "pdf",
        img: base_url + "/13/cancellation-poster-icon.svg",
        child: false,
        fullScreen: false,
        unlockAt: 3,
        url: ["cancellation-poster.jpg"],
        showReadState: true,
    },
    {
        label: "Tarot Event Pitch",
        type: "pdf",
        img: base_url + "/13/tarot-event-pitch-icon.svg",
        child: false,
        fullScreen: false,
        unlockAt: 4,
        url: ["tarot-event-pitch.jpg"],
        showReadState: true,
    },


    {
        label: "Expanded Kingdom Map",
        type: "pdf",
        img: base_url + "/13/expanded-kingdom-map-icon.svg",
        child: false,
        fullScreen: false,
        unlockAt: 4,
        url: ["expanded-kingdom-map.jpg"],
        showReadState: true,
    },
    {
        label: "Company Memo",
        type: "pdf",
        img: base_url + "/13/company-memo-icon.svg",
        child: false,
        fullScreen: false,
        unlockAt: 4,
        url: ["company-memo.jpg"],
        showReadState: true,
    },

    {
        label: "Vivian's Statement",
        type: "video",
        img: base_url + "/13/vivians-statement-video-icon.svg",
        child: false,
        fullScreen: false,
        url: "vivians-statement-video.mp4",
        unlockAt: 5,
        showReadState: true,
    },
    {
        label: "Updated Screenshots",
        type: "pdf",
        img: base_url + "/13/updated-screenshots-icon.svg",
        child: false,
        fullScreen: false,
        unlockAt: 5,
        url: ["updated-screenshots.jpg"],
        showReadState: true,
    },
    {
        label: "Home Security Stills",
        type: "folder",
        img: base_url + "/13/home-security-stills-folder-icon.svg",
        fullScreen: false,
        unlockAt: 5,
        showReadState: true,
        children: [
            {
                label: "Home Security Stills",
                type: "pdf",
                img: base_url + "/13/home-security-stills-image-icon.svg",
                child: true,
                fullScreen: false,
                unlockAt: 5,
                url: ["home-security-stills.jpg"],
                showReadState: true,
            },
        ]
    },


    {
        label: "Stakeout Notes",
        type: "pdf",
        img: base_url + "/13/stakeout-notes-pdf-icon.svg",
        child: false,
        fullScreen: false,
        unlockAt: 6,
        url: ["stakeout-notes-pg1.jpg", "stakeout-notes-pg2.jpg", "stakeout-notes-pg3.jpg", "stakeout-notes-pg4.jpg"],
        showReadState: true,
    }, {
        label: "Letter Of Resignation",
        type: "pdf",
        img: base_url + "/13/letter-of-resignation-icon.svg",
        child: false,
        fullScreen: false,
        unlockAt: 6,
        url: ["letter-of-resignation.jpg"],
        showReadState: true,
    },
    {
        label: "Chester's Arrest Record",
        type: "pdf",
        img: base_url + "/13/chesters-arrest-record-icon.svg",
        child: false,
        fullScreen: false,
        unlockAt: 6,
        url: ["chesters-arrest-record.jpg"],
        showReadState: true,
    },

]


